// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pet-calculator-index-js": () => import("./../src/pages/pet-calculator/index.js" /* webpackChunkName: "component---src-pages-pet-calculator-index-js" */),
  "component---src-pages-pet-index-js": () => import("./../src/pages/pet/index.js" /* webpackChunkName: "component---src-pages-pet-index-js" */),
  "component---src-pages-thankyou-js": () => import("./../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

